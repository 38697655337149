export enum CurrencyType {
  RUB = 'RUB',
  USD = 'USD',
  EUR = 'EUR',
  CNY = 'CNY',
}

export enum PaymentType {
  DEFAULT = 'DEFAULT',
  REVERSING = 'REVERSING',
}

export enum IssueState {
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED',
}
