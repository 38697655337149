<div class="modal-header">
  <h4 class="modal-title">Обновление заказа</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">x
  </button>
</div>
<div class="modal-body">

  <div class="create-form">
    <form [formGroup]="updateOrderedProductsForm">

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="product">Товар</label>
        <div class="col-8 input-group align-content-center"
             id="product">
          {{ productOrder.order_line.product.name }}
        </div>
      </div>

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="shipper">Поставщик</label>
        <div class="col-8">
          <ng-select [items]="shipperList"
                     class="form-control custom-select"
                     id="shipper"
                     placeholder="Наименование"
                     bindLabel="title"
                     bindValue="id"
                     formControlName="shipper"
                     autofocus="true"
                     [clearable]="false"
                     [hideSelected]="true"
                     [loading]="selectLoading"
                     [typeahead]="shipperInput$">
          </ng-select>
        </div>
      </div>

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="amount">Количество</label>
        <div class="col-8">
          <input formControlName="quantity"
                 class="form-control"
                 placeholder="Введите количество"
                 type="text"
                 id="amount"
                 appOnlyNumber/>
        </div>
      </div>

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="expected_date">Ожидаемая дата</label>
        <div class="col-8 input-group">
          <input formControlName="expected_date"
                 class="form-control"
                 placeholder="Введите ожидаемую дату"
                 type="text"
                 id="expected_date"
                 ngbDatepicker
                 [footerTemplate]="footerTemplate"
                 #d="ngbDatepicker"/>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar"
                    type="button"
                    (click)="d.toggle()">
              <i class="fe fe-calendar"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="comment">Комментарий</label>
        <div class="col-8">
          <textarea formControlName="comment"
                 class="form-control"
                 placeholder="Комментарий"
                 type="text"
                 id="comment"></textarea>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-8">
          <ng-select [items]="productOrderStates"
                     class="form-control custom-select"
                     placeholder="Статус"
                     bindLabel="title"
                     bindValue="code"
                     formControlName="state"
                     [hideSelected]="true"
                     [loading]="selectLoading">
          </ng-select>
        </div>
      </div>

      <div class="row justify-content-end mr-0">
        <button type="button"
                class="btn btn-outline-danger mr-2"
                [disabled]="isLoading"
                (click)="activeModal.dismiss('Close click')">Отменить
        </button>
        <button *ngIf="!isProductOrderNotRequired" type="submit"
                class="btn btn-outline-success"
                [disabled]="isLoading || !updateOrderedProductsForm.valid"
                (click)="updateProductOrder()">Сохранить
                <img *ngIf="isLoading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpK
            SkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2M
            IOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlF
            UYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmK
            twHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0
            R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6Twf
            wyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSl
            KAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKA
            AAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
        </button>

      </div>
    </form>

    <ng-template #footerTemplate>
      <hr class="my-0">
      <button class="btn btn-primary btn-sm m-2 float-left" (click)="updateOrderedProductsForm.controls.payment_date.setValue(today); d.close()">Сегодня</button>
      <button class="btn btn-secondary btn-sm m-2 float-right" (click)="d.close()">Закрыть</button>
    </ng-template>

  </div>
</div>

